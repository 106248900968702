<template>
  <div class="section">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li><router-link to="/admin">Admin Settings</router-link></li>
        <li class="is-active"><router-link to="/dashboard/entity-management" aria-current="true">entity-management</router-link></li>
      </ul>
    </nav>
    <div class="container">
      <h1 class="title">Entity Management</h1>
      <div class="buttons is-size-12">
        <button class="button is-primary" @click="openCreateModal">Create New Entity</button>
        <button v-if="!isSandbox" 
                  class="button is-warning ml-4" 
                  @click="moveToSandbox">
            Move to Sandbox
        </button>
      </div>
      <div class="column is-size-12">
        <table class="table is-small is-size-7">
          <thead>
            <tr>
              <th>Logo</th> <!-- Added Logo header -->
              <th>Name</th>
              <th>Entity Code</th>
              <th>Email</th>
              <th>TIN No</th>
              <th>Identity No</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="entity in entities" :key="entity.id">
              <td class="is-size-7 is-vcentered">
                <img 
                  :src="getEntityLogo(entity)" 
                  alt="Entity Logo" 
                  class="entity-logo"
                />
              </td>
              <td class="is-size-7 is-vcentered">{{ entity.name }}</td>
              <td class="is-size-7 is-vcentered">{{ entity.entity_code }}</td>
              <td class="is-size-7 is-vcentered">{{ entity.email }}</td>
              <td class="is-size-7 is-vcentered">{{ entity.tin_no }}</td>
              <td class="is-size-7 is-vcentered">{{ entity.identity_number }}</td>
              <td class="is-size-7 is-vcentered">
                <button class="button is-info is-small ml-2 is-vcentered" @click="openEditModal(entity)">Update</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <create-edit-modal
      v-if="isModalOpen"
      :is-edit="isEdit"
      :entity="selectedEntity"
      :states="states"
      @close="closeModal"
      @submit="handleEntitySubmit"
      @logo-updated="updateLogoImage"
    ></create-edit-modal>
  </div>
</template>

<script>
import axios from 'axios';
import CreateEditModal from './CreateEditModal.vue'; // Assuming you create a separate component for the modal
import { toast } from 'bulma-toast';

export default {
  components: {
    CreateEditModal,
  },
  data() {
    return {
      entities: [],
      states: [],
      isModalOpen: false,
      isEdit: false,
      selectedEntity: null,
    };
  },
  methods: {
    moveToSandbox() {
      // Make the API request to change sandbox status to true
      axios.patch(`/api/v1/authentication/tenant/sandbox/`, { sandbox: true }, {
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
      .then(() => {
        toast({ message: 'Your account has been moved to sandbox mode successfully.', type: 'is-success' });
        this.isSandbox = true; // Update local state to reflect the change
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          toast({ message: 'Only superusers are allowed to perform this action.', type: 'is-danger' });
        } else if (error.response && error.response.status === 404) {
          toast({ message: 'Tenant not found.', type: 'is-danger' });
        } else {
          toast({ message: 'An error occurred while trying to move your account to sandbox.', type: 'is-danger' });
        }
      });
    },
    loadStates() {
      try {
        this.states = JSON.parse(process.env.VUE_APP_STATE_CODES);
      } catch (error) {
        console.error('Error parsing state codes:', error);
      }
    },
    openCreateModal() {
      this.isEdit = false;
      this.selectedEntity = null;
      this.isModalOpen = true;
    },
    openEditModal(entity) {
      this.isEdit = true;
      this.selectedEntity = { ...entity };
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    handleEntitySubmit(formData) {
      if (formData instanceof FormData) {
        if (this.isEdit) {
          this.updateEntity(formData);
        } else {
          this.createEntity(formData);
        }
      } else {
        console.error('Expected FormData object, received:', formData);
      }
    },
    createEntity(formData) {
      formData.append('created_by', localStorage.getItem('userid')); // Add created_by to formData

      axios.post('/api/v1/enrichment/entities/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Token ${localStorage.getItem('token')}`,
        }
      })
      .then(response => {
        this.entities.push(response.data);
        toast({ message: 'Entity created successfully', type: 'is-success' });
        this.closeModal();
      })
      .catch(() => {
        toast({ message: 'Error creating entity', type: 'is-danger' });
      });
    },
    async updateEntity(formData) {
      try {
        // Check if logo is a file
        const isFile = formData.get('logo') instanceof File;

        const config = {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`,
            'Content-Type': isFile ? 'multipart/form-data' : 'application/json',
          },
        };

        // If not a file, convert formData to a plain object
        const data = isFile ? formData : Object.fromEntries(formData.entries());

        // Make the request
        const response = await axios.put(`/api/v1/enrichment/entities/${this.selectedEntity.id}/`, data, config);

        // Check if response is 200
        if (response.status === 200) {
          const index = this.entities.findIndex(e => e.id === this.selectedEntity.id);
          if (index !== -1) {
            // Update the entity data directly in the array
            this.entities.splice(index, 1, response.data); // Replace the old entity with the updated one
            this.updateLogoImage(this.entities[index].entity_code);
          }
          toast({ message: 'Entity updated successfully', type: 'is-success' });
          this.closeModal();
        } else {
          // Handle unexpected status codes
          toast({ message: 'Unexpected response status', type: 'is-warning' });
        }
      } catch (error) {
        // Log error details to understand the issue
        console.error('Error updating entity:', error);

        // Display error message to the user
        if (error.response) {
          toast({ message: `Error updating entity: ${error.response.data.detail || error.message}`, type: 'is-danger' });
        } else {
          toast({ message: 'Network or server error occurred', type: 'is-danger' });
        }
      }
    },


    deleteEntity(entityId) {
      if (confirm('Are you sure you want to delete this entity?')) {
        axios.delete(`/api/v1/enrichment/entities/${entityId}/`, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`,
          }
        })
        .then(() => {
          this.entities = this.entities.filter(entity => entity.id !== entityId);
          toast({ message: 'Entity deleted successfully', type: 'is-success' });
          this.closeModal();
        })
        .catch(() => {
          toast({ message: 'Error deleting entity', type: 'is-danger' });
        });
      }
    },
    updateLogoImage(entityCode) {
      const entity = this.entities.find(e => e.entity_code === entityCode);
      if (entity) {
        entity.logo = `${entity.logo}?timestamp=${new Date().getTime()}`; // Force image refresh
      }
    },
    getEntityLogo(entity) {
      return entity.logo || require('@/assets/default-entity-logo.png');
    }
  },

  created() {
    axios.get('/api/v1/enrichment/entities/', {
      headers: {
        'Authorization': `Token ${localStorage.getItem('token')}`,
      }
    })
    .then(response => {
      this.entities = response.data.results;
    })
    .catch(() => {
      toast({ message: 'Error loading entities', type: 'is-danger' });
    });
    this.loadStates();
  },
};
</script>

<style scoped>
.entity-logo {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}

.ml-2 {
  margin-left: 0.5rem;
}
</style>
