<template>
    <div class="card">
      <div class="card-content">
        <div class="bar-chart-container">
          <Bar :data="chartData" :options="chartOptions"></Bar>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { Bar } from 'vue-chartjs';
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
  
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
  
  export default {
    name: 'BarChartComponent',
    components: {
      Bar,
    },
    props: {
      data: {
        type: Array,
        required: true,
      },
    },
    computed: {
      chartData() {
        const labels = this.data.map(item => item.tenant_account);
        const datasets = [
          {
            label: 'Invoices by Creator',
            backgroundColor: '#42b983',
            borderColor: '#42b983',
            data: this.data.map(item => item.count),
          },
        ];
        return {
          labels,
          datasets,
        };
      },
      chartOptions() {
        return {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              stacked: false,
            },
            y: {
              stacked: false,
            },
          },
        };
      },
    },
  };
  </script>
  
  <style scoped>
  .bar-chart-container {
    width: 100%;
    height: 200px; /* Ensure the chart height matches the other charts */
  }
  </style>
  