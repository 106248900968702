<template>
  <div class="page-edit-invoice mt-5">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li><router-link to="/dashboard">Dashboard</router-link></li>
        <li><router-link to="/dashboard/invoices">Invoices</router-link></li>
        <li class="is-active"><router-link to="/dashboard/invoices/edit" aria-current="true">Edit</router-link></li>
      </ul>
    </nav>

    <div class="form-container">
      <div class="column is-12">
        <h1 class="title">Edit Invoice</h1>
      </div>
      <div class="form-container mt-5">
        <div class="columns mt-5">
          <div>
            <div class="pl-5">
              <label><strong>Document Type : {{ invoice.invoice_type }}</strong></label>
            </div>
          </div>
          <div class="pl-5">
            <label class="switch">
              <input type="checkbox" v-model="isSelfBilling" disabled>
              <span class="check"></span>
              <span class="control-label">Self Billing</span>
            </label>
          </div>
        </div>
        <div class="is-divider">
          <hr class="separator">
        </div>
        <div class="columns pl-4">
          <div class="column is-half">
            <h2 class="is-size-6 mb-1">Sender</h2>
            <div class="box mt-4 is-size-7" v-if="invoice.sender">
              <p><strong>{{ invoice.sender.name }}</strong></p>
              <p><strong>TIN No:</strong> {{ invoice.sender.tin_no }}</p>
              <p><strong>Identity No ({{ invoice.sender.identity_type }}):</strong> {{ invoice.sender.identity_number }}</p>
              <p><strong>Email:</strong> {{ invoice.sender.email }}</p>
              <p><strong>Phone:</strong> {{ invoice.sender.contact_number }}</p>
              <p v-if="invoice.sender.address1">{{ invoice.sender.address1 }}</p>
              <p v-if="invoice.sender.address2">{{ invoice.sender.address2 }}</p>
              <p v-if="invoice.sender.zipcode || invoice.sender.city">{{ invoice.sender.zipcode }} {{ invoice.sender.city }}</p>
              <p v-if="invoice.sender.country">{{ invoice.sender.country }}</p>
            </div>
          </div>

          <div class="column is-half">
            <h2 class="is-size-6 mb-1">Customers</h2>
            <div class="box mt-4 is-size-7" v-if="invoice.customer">
              <p><strong>{{ invoice.customer.name }}</strong></p>
              <p><strong>TIN No:</strong> {{ invoice.customer.tin_no }}</p>
              <p><strong>Identity No ({{ invoice.customer.identity_type }}):</strong> {{ invoice.customer.identity_number }}</p>
              <p><strong>Email:</strong> {{ invoice.customer.email }}</p>
              <p><strong>Phone:</strong> {{ invoice.customer.contact_number }}</p>
              <p v-if="invoice.customer.address1">{{ invoice.customer.address1 }}</p>
              <p v-if="invoice.customer.address2">{{ invoice.customer.address2 }}</p>
              <p v-if="invoice.customer.zipcode || invoice.customer.city">{{ invoice.customer.zipcode }} {{ invoice.customer.city }}</p>
              <p v-if="invoice.customer.country">{{ invoice.customer.country }}</p>
            </div>
          </div>
        </div>

        <div class="column is-12">
          <hr class="separator">
        </div>

        <div class="column is-12">
          <h2 class="is-size-5 mb-4">Header</h2>
        </div>
        <div class="columns">
          <div class="column is-6">
            <div class="column is-8">
              <div class="field is-size-7">
                <label>Invoice Number</label>
                <div class="control">
                  <input type="text" class="input is-small" v-model="invoice.invoice_number" disabled>
                </div>
              </div>

              <div class="field is-size-7">
                <label>Invoice Date</label>
                <div class="control">
                  <input type="date" class="input is-small" v-model="invoice.invoice_date" disabled>
                </div>
              </div>

              <div class="field is-size-7">
                <label>Currency</label>
                <div class="control">
                  <div class="select is-small">
                    <select v-model="invoice.invoice_currency" @change="handleCurrencyChange">
                      <option v-for="currency in currencies" :key="currency.code" :value="currency.code">
                        {{ currency.code }} - {{ currency.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div v-if="invoice.invoice_currency !== 'MYR'" class="field is-size-7">
                <label>Currency Exchange Rate *</label>
                <div class="control">
                  <input type="number" step="0.0001" class="input is-small" v-model="invoice.currency_exchange_rate" required>
                </div>
              </div>

              <div class="field is-size-7">
                <label>Store Code *</label>
                <div class="select is-small">
                  <select v-model="invoice.sender_store_code">
                    <option v-for="store in userProfile.store_code" :key="store.id" :value="store.code">
                      {{ store.code }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>


          <div class="column is-6">
            <div class="column is-8">
              <div class="columns field is-size-7 ">
                <div class="column">
                  <label>Rounding Amount</label>
                  <div class="control" @change="updateTotals">
                    <input type="number" step="0.01" class="input is-small has-text-right" v-model="invoice.rounding_amount">
                  </div>
                </div>
                <div class="column">
                  <label>Pre-payment Amount</label>
                  <div class="control" @change="updateTotals">
                    <input type="number" step="0.01" class="input is-small has-text-right" v-model="invoice.prepayment_amount">
                  </div>
                </div>
              </div>
              <div v-if="invoice.invoice_type !== 'INVOICE' && invoice.invoice_type !== 'SELF BILLED INVOICE'">
                <div class="field  is-size-7">
                  <label>Original LHDN Invoice UUID</label>
                  <div class="control">
                    <input type="text" class="input is-small" v-model="invoice.original_inv_ref_number">
                  </div>
                </div>
                <div class="field is-size-7">
                  <label>Original Seller Invoice No</label>
                  <div class="control">
                    <input type="text" class="input is-small" v-model="invoice.original_inv_number">
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mt-5">
          <header class="card-header">
            <p class="card-header-title">Items</p>
          </header>
          <div class="card-content">
          <ItemForm
            v-for="(item, index) in invoice.items"
            :key="item.id"
            :initialItem="item"
            @updateItem="updateItem($event, index)"
            @delete-item="deleteItem"
            :classification-options="classificationOptions"
            :description-options="descriptionOptions"
          ></ItemForm>
          <button class="button is-small is-info" @click="addItem">+ Add Item</button>
        </div>
      </div>

        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Document Summary</p>
          </header>
          <div class="card-content">
            <div class="columns">
              <div class="column is-half">
                <div class="field column is-10">
                  <label>Additional Comments</label>
                    <div class="control is-small">
                      <input type="text" class="input is-small" v-model="invoice.document_remarks">
                    </div>
                </div>
                <div v-if="invoice.tax_rate == 0 || invoice.tax_rate === null" class="field column is-6">
                  <div class="column is-size-3">
                    <div class=" is-size-6">
                      <label>Tax Exemption Reason</label>
                      <div class="control is-small">
                        <input type="text" class="input is-small" v-model="invoice.tax_exemption_reason">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column is-12">
                <h2 class="is-size-5 mb-4">Invoice Summary</h2>
                <p><strong>Gross amount</strong>: {{ formatCurrency(invoice.total_excluding_tax) }}</p>
                <p><strong>Discount amount</strong>: {{ formatCurrency(invoice.total_discount_value) }}</p>
                <p><strong>Tax amount</strong>: {{ formatCurrency(invoice.total_tax_amount) }}</p>
                <p v-if="invoice.prepayment_amount !== '0.00'"><strong>Pre-payment Amount</strong>: {{ formatCurrency(invoice.prepayment_amount) }}</p>
                <p v-if="invoice.rounding_amount !== '0.00'"><strong>Rounding Amount</strong>: {{ formatCurrency(invoice.rounding_amount) }}</p>
                <p><strong>Payable amount</strong>: {{ formatCurrency(invoice.total_payable_amount) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    <div class="buttons mt-6 ml-6 pl-6">
      <button class="button is-primary" @click="previewInvoice">Preview</button>
      <button class="button is-success ml-3" @click="submitForm">Request Approval</button>
      <div v-if="serverResponse" class="notification is-info">
        {{ serverResponse }}
      </div>
    </div>
  </div>

  <InvoicePreview :showPreview="showPreview" :logo="previewLogo" :invoice="invoice" :sender="invoice.sender" :customer="invoice.customer" @close="showPreview = false" />
</template>

<script>
import axios from 'axios';
import { toast } from 'bulma-toast';
import ItemForm from '@/components/CreateInvoice/ItemForm.vue';
import InvoicePreview from '@/components/CreateInvoice/InvoicePreview.vue';
import 'bulma-switch/dist/css/bulma-switch.min.css';

export default {
  name: 'EditInvoice',
  components: {
    ItemForm,
    InvoicePreview,
  },
  data() {
    return {
      invoice: {
        sender: '',
        customer: '',
        invoice_type: '',
        invoice_number: '',
        invoice_date: '',
        invoice_currency: 'MYR',
        currency_exchange_rate: '',
        sender_store_code: '',
        document_type: '',
        original_inv_number: '',
        original_inv_ref_number: '',
        prepayment_amount: '0.00',
        document_remarks: '',
        items: [],
        total_excluding_tax: '0.00',
        tax_rate: 0,
        total_including_tax: '0.00',
        total_net_amount: '0.00',
        total_discount_value: '0.00',
        total_tax_amount: '0.00',
        total_payable_amount: '0.00',
        rounding_amount: '0.00',
        tax_exemption_reason: '',
      },
      selectedCustomer: '',
      selectedInvoiceType: '',
      serverResponse: '',
      previewLogo: null,
      showPreview: false,
      isSelfBilling: false,
      uuid: '', // Add a new data property for UUID
      currencies: [],
      userProfile: {
        sender_entities: [],
        store_code: [],
      },
    };
  },
  async mounted() {
    await this.getUserProfile();
    this.uuid = this.$route.params.uuid; // Assuming UUID is passed as a route parameter
    await this.fetchInvoice();
    this.setInvoiceDateToCurrentDate();
    await this.fetchCurrencies();
    this.setCurrentTime();
  },
  watch: {
    // Watch for changes in userProfile.store_code
    'userProfile.store_code': function (newStoreCode) {
      if (newStoreCode.length === 1) {
        this.invoice.sender_store_code = newStoreCode[0].code;
      }
    },
  },
  computed: {
    defaultTaxRate: {
      get() {
        return this.invoice.tax_rate !== null ? this.invoice.tax_rate : 0;
      },
      set(value) {
        this.invoice.tax_rate = value;
      }
    }
  },
  methods: {
    async fetchCurrencies() {
      try {
        const response = await axios.get('https://openexchangerates.org/api/currencies.json', {
          params: {
            app_id: 'YOUR_API_KEY' // Replace with your API key
          }
        });
        this.currencies = Object.entries(response.data).map(([code, name]) => ({
          code,
          name
        }));
      } catch (error) {
        console.error('Error fetching currencies:', error);
      }
    },
    handleCurrencyChange() {
      if (this.invoice.invoice_currency === 'MYR') {
        this.invoice.currency_exchange_rate = ''; // Clear exchange rate if MYR is selected
      }
    },
    setInvoiceDateToCurrentDate() {
      const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
      this.invoice.invoice_date = currentDate;
    },
    previewInvoice(invoice) {
      this.selectedInvoice = invoice;
      this.selectedCustomer = invoice.selectedCustomer;
      this.selectedSender = invoice.sender;
      
      // Check if selfbilling is true or false
      if (invoice.selfbilling) {
        this.previewLogo = this.selectedCustomer.logo || null;
      } else {
        // Use the sender logo from the invoice if selfbilling is false
        this.previewLogo = this.invoice.sender.logo;
      }

      // Show the invoice preview modal
      this.showPreview = true;
    },
    async fetchInvoice() {
      try {
        const response = await axios.get(`/api/v1/invoices/${this.uuid}/`);
        this.invoice = response.data;
        this.isSelfBilling = this.invoice.selfbilling;
        const matchingSender = this.userProfile.sender_entities.find(entity => entity.entity_code === this.invoice.sender_entity_code);

        // Set sender and customer based on selfbilling
        if (this.isSelfBilling) {
          const customerResponse = await axios.get(`/api/v1/customers/?entity_code=${this.invoice.sender_entity_code}`);
          this.invoice.sender = customerResponse.data[0]; // Assuming the API returns a list
          this.invoice.selectedCustomer = matchingSender; // Assuming the API returns a list
        } else {
          this.invoice.sender = matchingSender; // Assuming the API returns a list
        }

        // Set invoice type
        this.invoice.invoice_type = response.data.invoice_type;

        this.invoice.original_inv_number = response.data.original_inv_number;
        this.invoice.original_inv_ref_number = response.data.original_inv_ref_number;

        // Populate items
        this.invoice.items = response.data.items.map((item, index) => ({
          ...item,
          id: item.id || this.generateUniqueId(index) // Ensure each item has a unique id
        }));

        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(currentDate.getDate()).padStart(2, '0');
        this.invoice.invoice_date = `${year}-${month}-${day}`;

        this.invoice.buyer_name = this.invoice.customer.name,
        this.invoice.buyer_tin_no = this.invoice.customer.tin_no,
        this.invoice.buyer_identity_type = this.invoice.customer.identity_type,
        this.invoice.buyer_identity_number = this.invoice.customer.identity_number,
        this.invoice.buyer_sstregistration = this.invoice.customer.sst_no,
        this.invoice.buyer_email = this.invoice.customer.email,
        this.invoice.buyer_address1 = this.invoice.customer.address1,
        this.invoice.buyer_address2 = this.invoice.customer.address2,
        this.invoice.buyer_address3 = this.invoice.customer.address3,
        this.invoice.buyer_zipcode = this.invoice.customer.zipcode,
        this.invoice.buyer_city = this.invoice.customer.city,
        this.invoice.buyer_state = this.invoice.customer.state,
        this.invoice.buyer_country = this.invoice.customer.country,
        this.invoice.buyer_contact_number = this.invoice.customer.contact_number

      } catch (error) {
        console.error('Error fetching invoice:', error);
      }
    },
    async getUserProfile() {
      try {
        // Fetch and parse user profile data from local storage or API
        const senderEntities = JSON.parse(localStorage.getItem('sender_entities')) || [];
        const storeCode = JSON.parse(localStorage.getItem('store_code')) || [];

        // Set the userProfile state
        this.userProfile.sender_entities = senderEntities;
        this.userProfile.store_code = storeCode;
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    },
    async submitForm() {
      if (!this.validateForm()) {
        toast({
          message: 'Please complete all mandatory fields.',
          type: 'is-danger',
          dismissible: true,
          pauseOnHover: true,
          duration: 3000,
          position: 'bottom-right',
        });
        return;
      }

      this.invoice.customer = this.selectedCustomer;

      if (this.isSelfBilling) {
        this.invoice.selfbilling = true;
        this.invoice.sender_entity_code = this.invoice.sender.tin_no;
      }


      const formattedInvoiceData = this.formatInvoiceData();
      const requestBody = {
        source: "UI",
        selfbilling: this.isSelfBilling,
        invoices_data: [[formattedInvoiceData, this.invoice.items]]
      };

      try {
        const response = await axios.post('api/einvoicing_json/', requestBody);
        toast({
          message: 'The invoice was updated',
          type: 'is-success',
          dismissible: true,
          pauseOnHover: true,
          duration: 2000,
          position: 'bottom-right',
        });
        this.serverResponse = response.data;
        setTimeout(() => {
          this.$router.push('/dashboard/invoices');
        }, 2000);
      } catch (error) {
        console.log(error);
        this.serverResponse = error.response ? error.response.data : 'Error updating invoice';
      }
    },
    validateForm() {
      return (
        this.invoice.invoice_date &&
        this.invoice.invoice_currency &&
        this.invoice.items.length > 0
      );
    },
    formatCurrency(value) {
      if (!value) return 'MYR 0.00';
      return new Intl.NumberFormat('en-MY', { style: 'currency', currency: 'MYR' }).format(value);
    },
    formatInvoiceData() {
      return {
        approval_status: "Pending Approval",
        status: "Pending Approval",
        selfbilling: this.isSelfBilling,
        invoice_type: this.invoice.invoice_type,
        document_type: this.invoice.document_type,
        invoice_number: this.invoice.invoice_number,
        invoice_date: this.invoice.invoice_date,
        invoice_time: this.invoice.invoice_time,
        invoice_currency: this.invoice.invoice_currency,
        currency_exchange_rate: this.invoice.currency_exchange_rate,
        original_inv_number: this.invoice.original_inv_number,
        original_inv_ref_number: this.invoice.original_inv_ref_number,
        total_excluding_tax: parseFloat(this.invoice.total_excluding_tax),
        total_including_tax: parseFloat(this.invoice.total_including_tax),
        sender_entity_code: this.invoice.sender.entity_code,
        sender_store_code: this.invoice.sender_store_code,
        total_payable_amount: parseFloat(this.invoice.total_payable_amount),
        total_net_amount: parseFloat(this.invoice.total_net_amount),
        total_discount_value: parseFloat(this.invoice.total_discount_value),
        total_tax_amount: parseFloat(this.invoice.total_tax_amount),
        rounding_amount: parseFloat(this.invoice.rounding_amount),
        tax_rate: parseFloat(this.invoice.tax_rate),
        tax_type: this.invoice.tax_type,
        buyer_name: this.invoice.buyer_name,
        buyer_tin_no: this.invoice.buyer_tin_no,
        buyer_identity_type: this.invoice.buyer_identity_type,
        buyer_identity_number: this.invoice.buyer_identity_number,
        buyer_sstregistration: this.invoice.buyer_sstregistration,
        buyer_email: this.invoice.buyer_email,
        buyer_address1: this.invoice.buyer_address1,
        buyer_address2: this.invoice.buyer_address2,
        buyer_address3: this.invoice.buyer_address3,
        buyer_zipcode: this.invoice.buyer_zipcode,
        buyer_city: this.invoice.buyer_city,
        buyer_state: this.invoice.buyer_state,
        buyer_country: this.invoice.buyer_country,
        buyer_contact_number: this.invoice.buyer_contact_number,
      };
    },
    generateUniqueId(index) {
      const timestamp = Date.now();
      return parseInt(`${timestamp}${index}`);
    },
    addItem() {
      const index = this.invoice.items.length;
      const uniqueId = this.generateUniqueId(index);
      this.invoice.items.push({
        id: uniqueId,
        classification: '',
        item_description: '',
        unit_price: '0.00',
        quantity: 1,
        tax_rate: 0,
        tax_type: '',
        subtotal: '0.00',
        total_excluding_tax: '0.00',
        tax_amount: '0.00',
        discount_rate: '0.00',
        discount_amount: '0.00',
        unit_of_measurement: 'EA'
      });
    },
    deleteItem(itemId) {
      this.invoice.items = this.invoice.items.filter(item => item.id !== itemId);
      this.updateTotals();
    },
    updateItem(updatedItem, index) {
      this.invoice.items[index] = updatedItem;
      this.updateTotals();
    },
    updateTotals() {
      let net_amount = 0;
      let tax_amount = 0;
      let disc_amount = 0;

      for (let i = 0; i < this.invoice.items.length; i++) {
        const item = this.invoice.items[i];
        const item_net_amount = parseFloat(item.unit_price) * item.quantity;
        const discount = item_net_amount * (parseFloat(item.discount_rate) / 100);
        const item_tax_amount = (item_net_amount - discount) * (item.tax_rate / 100);

        item.subtotal = item_net_amount.toFixed(2);
        item.total_excluding_tax = (item_net_amount - discount).toFixed(2);
        item.tax_amount = item_tax_amount.toFixed(2);
        item.discount_amount = discount.toFixed(2);

        tax_amount += item_tax_amount;
        net_amount += item_net_amount;
        disc_amount += discount;
      }
      const rounding_amount = parseFloat(this.invoice.rounding_amount) || 0;
      const prepayment_amount = parseFloat(this.invoice.prepayment_amount) || 0;

      this.invoice.total_excluding_tax = net_amount.toFixed(2);
      this.invoice.total_discount_value = disc_amount.toFixed(2);
      this.invoice.total_tax_amount = tax_amount.toFixed(2);
      this.invoice.total_net_amount = (net_amount - disc_amount).toFixed(2);
      this.invoice.total_including_tax = (net_amount + tax_amount).toFixed(2);
      this.invoice.total_payable_amount = (net_amount + tax_amount + rounding_amount - prepayment_amount).toFixed(2);

      // Set tax exemption reason to "NOT APPLICABLE" if tax rate is 0
      if (this.invoice.tax_rate == 0) {
        this.invoice.tax_exemption_reason = "NOT APPLICABLE";
      }
    },
    setCurrentTime() {
      const now = new Date();
      const adjustedTime = new Date(now.getTime() - 8 * 60 * 60 * 1000); // Subtract 8 hours

      // Check if the adjusted time is on the same day
      if (now.getDate() !== adjustedTime.getDate()) {
        // If it's not, set the time to 00:00:01
        this.invoice.invoice_time = '00:00:01';
      } else {
        // Otherwise, use the adjusted time
        this.invoice.invoice_time = adjustedTime.toISOString().split('T')[1].split('.')[0];
      }
    },
    formatDate(date) {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    }
  }
};
</script>

<style lang="scss">
  .select, .select select {
    width: 100%;
  }
  .form-container {
    max-width: 1050px;
    margin: auto;
    padding: 20px;
    align-items: center;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  .switch {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .switch input {
    display: none;
  }
  .switch .check {
    width: 40px;
    height: 20px;
    background: #ddd;
    border-radius: 20px;
    position: relative;
    transition: 0.3s;
  }
  .switch .check::after {
    content: '';
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s;
  }
  .switch input:checked + .check {
    background: #48c774;
  }
  .switch input:checked + .check::after {
    left: 20px;
  }
  .switch .control-label {
    margin-left: 10px;
  }
</style>
