<template>
  <div class="page-my-account mt-5">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li><router-link to="/dashboard">Dashboard</router-link></li>
        <li class="is-active"><router-link to="/dashboard/my-account" aria-current="true">My account</router-link></li>
      </ul>
    </nav>

    <h1 class="title">My account</h1>

    <div class="columns is-vcentered">
      <div class="column is-half">
        <div class="image-container">
          <img 
          :src="profileImageUrl || require('@/assets/default-user-photo.png')" 
          alt="User Image" 
          class="user-image" />
          <button class="button is-small is-primary edit-button" @click="triggerFileInput">Edit</button>
          <input type="file" ref="fileInput" @change="onFileChange" style="display: none;" />
        </div>
      </div>
      <div class="column is-half">
        <div class="content">
          <p><strong>Username: </strong>{{ userProfile.username }}</p>
          <p><strong>Name: </strong>{{ userProfile.first_name }} {{ userProfile.last_name }}</p>
          <p><strong>Access Type: </strong>{{ userProfile.access_type }}</p>
          <p><strong>Entities:</strong></p>
          <ul>
            <li v-for="entity in userProfile.sender_entities" :key="entity.entity_code">{{ entity.name }}</li>
          </ul>
          <p><strong>Permissions:</strong></p>
          <ul>
            <li v-for="(group, index) in userProfile.groups" :key="index">{{ formatGroupName(group) }}</li>
          </ul>
        </div>
      </div>
    </div>

    <hr>

    <div class="buttons">
      <button @click="logout" class="button is-danger">Log out</button>
      <button @click="openPasswordResetModal" class="button is-primary">Set New Password</button>
    </div>


    <!-- Modal for setting new password -->
    <div v-if="showPasswordModal" class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Set New Password</p>
          <button class="delete" aria-label="close" @click="closePasswordResetModal"></button>
        </header>
        <section class="modal-card-body">
          <div class="field">
            <label class="label">New Password</label>
            <div class="control">
              <input v-model="newPassword" class="input" type="password" placeholder="Enter new password">
            </div>
          </div>

          <div class="field">
            <label class="label">Confirm New Password</label>
            <div class="control">
              <input v-model="confirmNewPassword" class="input" type="password" placeholder="Confirm new password">
            </div>
          </div>

          <p v-if="passwordError" class="help is-danger">{{ passwordError }}</p>
        </section>
        <footer class="buttons modal-card-foot">
          <button class="button is-success" @click="submitPasswordReset">Save changes</button>
          <button class="button" @click="closePasswordResetModal">Cancel</button>
        </footer>
      </div>
    </div>

  </div>
</template>

  
<script>
import axios from 'axios';
import { toast } from 'bulma-toast';

export default {
  name: 'MyAccount',
  data() {
    return {
      userProfile: {
        username: '',
        access_type: '',
        first_name: '',
        last_name: '',
        sender_entities: [],
        groups: []
      },
      showPasswordModal: false,
      newPassword: '',
      confirmNewPassword: '',
      passwordError: '',
      selectedFile: null,
      profileImageUrl: '' // URL of the user profile image
    };
  },
  async mounted() {
    this.getUserProfile();
  },
  methods: {
    formatGroupName(group) {
      // Replace underscores with spaces and capitalize the words
      return group.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },
    getUserProfile() {
      try {
        // Fetch the user data from localStorage
        this.userProfile = {
          username: localStorage.getItem('username'),
          access_type: localStorage.getItem('access_type'),
          first_name: localStorage.getItem('first_name'),
          last_name: localStorage.getItem('last_name'),
          sender_entities: JSON.parse(localStorage.getItem('sender_entities')) || [],
          groups: JSON.parse(localStorage.getItem('permissions')) || [],
        };
        this.profileImageUrl = localStorage.getItem('image'); // Replace with your actual default image URL
      } catch (error) {
        console.log('Error retrieving user profile data:', error);
      }
    },
    openPasswordResetModal() {
      this.showPasswordModal = true;
    },
    closePasswordResetModal() {
      this.showPasswordModal = false;
      this.newPassword = '';
      this.confirmNewPassword = '';
      this.passwordError = '';
    },
    async submitPasswordReset() {
      if (this.newPassword !== this.confirmNewPassword) {
        this.passwordError = 'Passwords do not match.';
        return;
      }

      try {
        await axios.post('/api/v1/user/reset-password-vv/', {
          new_password: this.newPassword,
        });

        toast({
          message: 'Password successfully updated.',
          type: 'is-success',
          dismissible: true,
          pauseOnHover: true,
          duration: 5000,
          position: 'top-right'
        });

        // Close modal and reset inputs
        this.closePasswordResetModal();
      } catch (error) {
        console.error('Error updating password:', error);
        this.passwordError = 'Failed to update password. Please try again.';
      }
    },
    onFileChange(event) {
      this.selectedFile = event.target.files[0];
      this.uploadImage(); // Automatically upload the image after selection
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async uploadImage() {
      if (!this.selectedFile) {
        alert('Please select a file to upload.');
        return;
      }

      const formData = new FormData();
      formData.append('image', this.selectedFile);

      try {
        const response = await axios.put('/api/v1/user-profile/image/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Token ${this.$store.state.token}`
          }
        });

        this.profileImageUrl = response.data.image;

        // Update the image in localStorage
        localStorage.setItem('image', response.data.image);

        toast({
          message: 'Profile image updated successfully.',
          type: 'is-success',
          dismissible: true,
          pauseOnHover: true,
          duration: 5000,
          position: 'top-right'
        });
      } catch (error) {
        console.error('Error uploading image:', error);
        toast({
          message: 'Error uploading image. Please try again.',
          type: 'is-danger',
          dismissible: true,
          pauseOnHover: true,
          duration: 5000,
          position: 'top-right'
        });
      }
    },
    async logout() {
      try {
        await axios.post("/api/v1/token/logout/");
        
        localStorage.clear(); // Clear all localStorage items

        axios.defaults.headers.common["Authorization"] = "";

        this.$store.commit('removeToken');

        this.$router.push('/');
      } catch (error) {
        console.log(error);
      }
    },
  }

};
</script>

<style scoped>
.user-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.image-container {
  position: relative;
  display: inline-block;
}

.edit-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 5px 10px;
  font-size: 0.75rem;
}

.content p {
  margin-bottom: 0.5rem;
}

</style>


  