<template>
  <div class="column is-multiline item-form">
    <div class="columns">

      <div class="field column is-1">
        <label class="label is-size-7 has-text-left">Class #</label>
        <div class="control">
          <input type="text" class="input is-small has-text-left" v-model="item.classification" disabled>
        </div>
      </div>

      <div class="column is-4">
        <label class="label is-size-7 has-text-left">Description</label>
        <div class="control">
          <multiselect
            v-if="!editDescription"
            v-model="selectedItem"
            :options="items"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="item_description"
            track-by="item_description"
            placeholder="Select.."
            @select="updateSelectedItem"
            class="is-size-7 multiselect-custom"
          ></multiselect>
          <!-- Editable input field for modifying the description -->
          <div v-else>
            <input
              type="text"
              class="field input is-small is is-vcentered"
              v-model="item.item_description"
              placeholder="Edit description"
              @input="updateItem"
            />
            <!-- Icon to switch back to multiselect mode -->
          </div>
        </div>
      </div>

      <div class="field column is-2">
        <label class="label is-size-7">Unit Price</label>
        <div class="control">
          <input type="number" step="0.01" class="input is-small" v-model.number="item.unit_price" @input="updateItem" required>
        </div>
      </div>

      <div class="field column is-1">

        <label class="label is-size-7">Qty</label>
        <div class="control">
          <input type="number" class="input is-small" v-model.number="item.quantity" @input="updateItem" required>
        </div>
      </div>

      <div class="field column is-1">
        <label class="label is-size-7">Disc %</label>
        <div class="control">
          <input type="number" step="1" class="input is-small" v-model.number="item.discount_rate" @input="updateItem" required>
        </div>
      </div>

      <div class="field column is-1">
        <label class="label is-size-7">Disc Amt</label>
        <div class="control">
          <input type="number" step="1" class="input is-small" v-model.number="item.discount_amount" disabled>
        </div>
      </div>

      <div class="field column is-2">
        <label class="label is-size-7">Gross amount</label>
        <div class="control">
          <input type="text" class="input is-small" :value="gross_amount" disabled>
        </div>
      </div>


  <!-- 
      <div  v-if="editDescription" class="column is-1 ml-3">
        <button @click="editDescription = false" class="delete is-small change-icon is-vcentered">
          <span class="icon">
            <i class="fas fa-pencil-alt"></i>
          </span>
        </button>
      </div> -->

      
    </div>

    <div class="columns">
      <div class="field column is-3 has-text-left">
        <label class="label is-size-7 has-text-left">Tax Type</label>
        <div class="select is-small">
          <select v-model="item.tax_type" :disabled="isSelfBilling">
            <option v-for="tax in taxTypes" :key="tax.Code" :value="tax.Code">
              {{ tax.Description }}
            </option>
          </select>
        </div>
      </div>

      <div class="field column is-2 has-text-left">
        <div v-if="item.tax_type !== '06'">
          <label class="label is-size-7 has-text-left">Tax rate</label>
          <div class="control">
            <input type="number" step="1" class="input is-small" v-model.number="item.tax_rate" @input="updateItem" required>        
          </div>
        </div>
      </div>

      <div class="field column is-2 has-text-left">
        <div v-if="item.tax_type !== '06'">

          <label class="label is-size-7 has-text-left">Tax Amount</label>
          <div class="control">
            <input type="number" class="input is-small" v-model.number="item.tax_amount" disabled>        
          </div>
        </div>
      </div>

      <div class="field column is-3 has-text-left">
        <div v-if="item.tax_rate == 0 && item.tax_type !== ''">
          <label class="label is-size-7 has-text-left">Tax Exemption Reason</label>
          <div class="control">
            <input type="text" class="input is-small has-text-left" v-model="item.tax_exemption_reason">
          </div>
        </div>
      </div>

     

      <div class="column is-1"></div>

      <div class="column is-1 is-vcentered mt-5 ml-3">
        <button @click="$emit('delete-item', item.id)" class="delete is-small field">
          <span class="icon">
            <i class="fas fa-trash-alt"></i>
          </span>
        </button>
      </div>



    </div>

  

  </div>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';

export default {
  name: 'ItemForm',
  components: {
    Multiselect
  },
  props: {
    initialItem: Object
  },
  data() {
    return {
      item: {
        ...this.initialItem,
        discount_rate: this.initialItem.discount_rate || 0  // Set default value to 0
      },
      items: [],
      taxTypes: [],
      selectedItem: null,
      editDescription: false  // Toggle for description editing
    };
  },
  computed: {
    gross_amount() {
      if (this.item.unit_price && this.item.quantity && this.item.tax_rate != null) {
        const total_excluding_tax = parseFloat(this.item.unit_price) * this.item.quantity;
        const disc_amount = total_excluding_tax * (this.item.discount_rate / 100);
        const gross_amount = (total_excluding_tax - disc_amount) + ((total_excluding_tax - disc_amount) * (this.item.tax_rate / 100));
        return gross_amount.toFixed(2);  // Ensure two decimal points
      }
      return '0.00';
    },
  },
  async mounted() {
    await this.fetchTaxTypes();
    },
  methods: {
    updateItem() {
      if (this.item.unit_price && this.item.quantity && this.item.tax_rate != null) {
        const total_excluding_tax = parseFloat(this.item.unit_price) * this.item.quantity;
        const disc_amount = total_excluding_tax * (this.item.discount_rate / 100);
        this.item.total_excluding_tax = (total_excluding_tax - disc_amount).toFixed(2);  // Ensure two decimal points
        this.item.subtotal = total_excluding_tax.toFixed(2);
        this.item.tax_amount = ((total_excluding_tax - disc_amount) * (this.item.tax_rate / 100)).toFixed(2);
        this.item.discount_amount = disc_amount.toFixed(2);
        if (this.item.tax_rate == 0) {
          this.item.tax_exemption_reason = "NOT APPLICABLE";  // Ensure tax_exemption_reason is set to "NOT APPLICABLE"
        } else {
          delete this.item.tax_exemption_reason;  // Remove tax_exemption_reason if tax_rate is not 0
        }
        this.$emit('updateItem', this.item);
      }
    },
    async fetchTaxTypes() {
        try {
          const response = await axios.get('/api/v1/invoiceportal/tax-types/');
          this.taxTypes = JSON.parse(response.data);
        } catch (error) {
          console.error('Error fetching tax types:', error);
        }
      },
    updateSelectedItem(selectedItem) {
      if (selectedItem) {
        this.item.classification = selectedItem.classification;
        this.item.item_description = selectedItem.item_description;
        this.item.tax_type = selectedItem.tax_type;
        this.item.tax_rate = selectedItem.tax_rate;
        this.updateItem();
        this.editDescription = true;  // Switch to edit mode after selecting an item
      }
    },
    fetchItems() {
      axios.get('/api/v1/portal-items')
        .then(response => {
          this.items = response.data;
          // Set selectedItem based on initialItem
          this.selectedItem = this.items.find(i => i.item_description === this.item.item_description);
          // Set tax_exemption_reason if tax_rate is 0
          if (this.item.tax_rate == 0) {
            this.item.tax_exemption_reason = "NOT APPLICABLE";
          }
        })
        .catch(error => {
          console.error('Error fetching items:', error);
        });
    }
  },
  created() {
    this.fetchItems();
  }
};
</script>

<style scoped>
.change-icon {
  cursor: pointer;
  color: #3273dc; /* Icon color */
  font-size: 1.2em; /* Adjust size as needed */
  margin-left: 10px; /* Space between input and icon */
}
.change-icon:hover {
  color: #2750a4; /* Darker shade on hover */
}

.field {
  column-gap: 2px;
  flex-direction: column;
  justify-content: flex-start;
}

.field .label {
  flex: 1;
  height: 1.2em; /* Adjust as needed */
  text-align: right;
}

.field .control .input,
.field .control .select select {
  line-height: 1.7em; /* Adjust as needed */
  font-size: 0.8em; /* Smaller font size */
  text-align: right;
}

.multiselect-custom .multiselect__single, 
.multiselect-custom .multiselect__placeholder,
.multiselect__input {
  line-height: 2.0em;
  font-size: 2.0em; /* Smaller font size */
}

.multiselect-custom .multiselect__select,
.multiselect-custom .multiselect__caret {
  font-size: 2.0em; /* Smaller icon size */
}

/* Hide stepper controls for number inputs */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
