<template>
  <div class="user-dashboard">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li><router-link to="/dashboard">Dashboard</router-link></li>
      </ul>
    </nav>
    <h1 class="title">Dashboard</h1>
    <div class="container">
      <div class="columns is-multiline is-flex is-align-items-stretch">
        <div class="column is-one-quarter">
          <router-link to="/dashboard/invoices">
            <div class="card equal-height has-background-grey-darker">
              <div class="card-content has-text-white">
                <p class="title is-4 has-text-white">Total Monthly</p>
                <p class="subtitle is-1 has-text-white has-text-right">{{ thisMonthInvoiceCount }}</p>
              </div>
            </div>
          </router-link>
        </div>
        <div class="column is-one-quarter">
          <router-link :to="{ path: '/dashboard/invoices', query: { tab: 'Submission Failed' } }">
            <div class="card equal-height has-background-grey-darker">
              <div class="card-content has-text-white">
                <p class="title is-4 has-text-white">Submission Failed</p>
                <p class="subtitle is-1 has-text-white has-text-right">{{ lhdnNotSuccessfulCount }}</p>
              </div>
            </div>
          </router-link>
        </div>
        <div v-if="canApprove" class="column is-one-quarter">
          <router-link to="/dashboard/invoices/approval">
            <div class="card equal-height has-background-grey-darker">
              <div class="card-content has-text-white">
                <p class="title is-4 has-text-white">Waiting Approvals</p>
                <p class="subtitle is-1 has-text-white has-text-right">{{ invoicesPendingApprovalCount }}</p>
              </div>
            </div>
          </router-link>
        </div>
        <div v-if="canCreateInvoice" class="column is-one-quarter">
          <router-link to="/dashboard/customers">
            <div class="card equal-height has-background-grey-darker">
              <div class="card-content has-text-white">
                <p class="title is-4 has-text-white">Total Customers</p>
                <p class="subtitle is-1 has-text-white has-text-right">{{ customerCount }}</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="columns">
        <div class="column is-8">
          <line-chart-component :data="invoicesOverMonths"></line-chart-component>
        </div>
        <div class="column is-4">
          <pie-chart-component :data="topBuyers"></pie-chart-component>
        </div>
      </div>
      <div class="column">
        <bar-chart-component :data="invoicesBySender"></bar-chart-component>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import LineChartComponent from '@/components/LineChart.vue';
import BarChartComponent from '@/components/BarChart.vue';
import PieChartComponent from '@/components/PieChart.vue';

export default {
  name: 'UserDashboard',
  components: {
    LineChartComponent,
    BarChartComponent,
    PieChartComponent,
  },
  data() {
    return {
      thisMonthInvoiceCount: 0,
      lhdnNotSuccessfulCount: 0,
      invoicesPendingApprovalCount: 0,
      customerCount: 0,
      invoicesOverMonths: [],
      topBuyers: [],
      invoicesBySender: [],
    };
  },
  computed: {
    canApprove() {
      return this.$store.getters.canApproveInvoice;
    },
    canCreateInvoice() {
      return this.$store.getters.canCreateInvoice;
    }
  },
  methods: {
    fetchData() {
      axios.get('/api/v1/this-month-invoice-count/').then(response => {
        this.thisMonthInvoiceCount = response.data.count;
      });
      axios.get('/api/v1/lhdn-not-successful-count/').then(response => {
        this.lhdnNotSuccessfulCount = response.data.count;
      });
      axios.get('/api/v1/invoices/pending/').then(response => {
        this.invoicesPendingApprovalCount = response.data.count;
      });
      axios.get('/api/v1/customer-count/').then(response => {
        this.customerCount = response.data.count;
      });
      axios.get('/api/v1/invoices-over-months/').then(response => {
        this.invoicesOverMonths = response.data;
      });
      axios.get('/api/v1/top-buyers/').then(response => {
        this.topBuyers = response.data;
      });
      axios.get('/api/v1/invoices-by-sender/').then(response => {
        this.invoicesBySender = response.data;
      });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.user-dashboard {
  padding: 20px;
}

.card-content {
  cursor: pointer;
}

.equal-height {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.has-background-grey-darker {
  background-color: #2e2e2e;
}

.has-text-white {
  color: #ffffff;
}
</style>
