<template>
    <div class="modal" :class="{ 'is-active': showPreview }">
      <div class="modal-background" @click="$emit('close')"></div>
      <div class="modal-content">
        <div class="box p-6">
          <!-- Draft Watermark -->
          <div class="watermark">DRAFT</div>
          <div class="header">
            <img :src="getEntityLogo()"  alt="Account Logo" class="sender-logo image is-7by2">
            <!-- <img src="https://demo-despaq-einvoice.s3.ap-southeast-1.amazonaws.com/sender_logo/Logo_DespaQ.png" alt="Sender Logo" class="sender-logo image is-7by2"> -->
            <h2>{{ getMappedInvoiceType(invoice.invoice_type) }}</h2>
          </div>
          <hr class="separator">
          <div class="buyer-info">
            <div class="left is-size-7">
              <div v-if="sender">
                <p class="div-title">SUPPLIER</p>
                <p>{{ sender.name }}</p>
                <p>{{ sender.address1 }}</p>
                <p v-if="sender.address2">{{ sender.address2 }}</p>
                <p v-if="sender.address3">{{ sender.address3 }}</p>
                <p>{{ sender.zipcode }}, {{ sender.city }}</p>
                <p>{{ sender.state }}, {{ sender.country }}</p>
                <p>Phone: {{ sender.contact_number }}</p>
                <p>Email: {{ sender.email }}</p>
                <p>TIN: {{ sender.tin_no }}</p>
                <p>IDENTITY ({{ sender.identity_type }}): {{ sender.identity_number }}</p>
                <p>MSIC Code: {{ sender.msic_code }}</p>
                <p>Description: {{ sender.business_description }}</p>
              </div>
            </div>
            <div class="right is-size-7">
              <p v-if="customer" class="div-title">BUYER</p>
              <p>{{ customer.name }}</p>
              <p>{{ customer.address1 }}</p>
              <p v-if="customer.address2">{{ customer.address2 }}</p>
              <p v-if="customer.address3">{{ customer.address3 }}</p>
              <p>{{ customer.zipcode }}, {{ customer.city }}</p>
              <p>{{ customer.state }}, {{ customer.country }}</p>
              <p>Phone: {{ customer.contact_number }}</p>
              <p>Email: {{ customer.email }}</p>
              <p>TIN: {{ customer.tin_no }}</p>
              <p>IDENTITY ({{ customer.identity_type }}): {{ customer.identity_number }}</p>
            </div>
          </div>
          <hr class="separator">
          <div class="identifiers">
            <div class="right is-size-7">
              <p>Document No: {{ invoice.invoice_number }}</p>
              <p>Invoice Date: {{ invoice.invoice_date }} {{ invoice.invoice_time }}</p>
              <p>Currency: {{ invoice.invoice_currency }}</p>
              <div v-if="invoice.invoice_type !== 'INVOICE' && invoice.invoice_type !== 'SELF BILLED INVOICE'">
                <p>Seller Org Inv No: {{ invoice.original_inv_number }}</p>
                <p>LHDN Org Inv UUID: {{ invoice.original_inv_ref_number }}</p>
              </div>
            </div>
          </div>
          <hr class="separator">
          <table class="line-items">
            <thead>
              <tr class="is-size-7">
                <th>Classification Code</th>
                <th>Description</th>
                <th>Quantity</th>
                <th>Unit Price</th>
                <th>Amount</th>
                <th>Discount</th>
                <th>Tax Amount</th>
                <th>Line Total</th>
              </tr>
            </thead>
            <tbody class="is-size-7">
              <tr v-for="item in invoice.items" :key="item.id">
                <td>{{ item.classification }}</td>
                <td>{{ item.item_description }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ formatCurrency(item.unit_price) }}</td>
                <td>{{ formatCurrency(item.subtotal) }}</td>
                <td>{{ item.discount_rate }} %</td>
                <td>{{ formatCurrency(item.tax_amount) }}</td>
                <td>{{ formatCurrency(item.total_excluding_tax) }}</td>
              </tr>
            </tbody>
          </table>
          <div class="totals is-size-7">
            <p>Subtotal: {{ formatCurrency(invoice.total_excluding_tax) }}</p>
            <p>Discount amount: {{ formatCurrency(invoice.total_discount_value) }}</p>
            <p>Total Exc Tax: {{ formatCurrency(invoice.total_net_amount) }}</p>
            <p>Tax Amount: {{ formatCurrency(invoice.total_tax_amount) }}</p>
            <p>Total Inc Tax: {{ formatCurrency(invoice.total_net_amount) }}</p>
            <p>Prepayment: {{ formatCurrency(invoice.prepayment_amount) }}</p>
          </div>
          <hr class="separator">
          <table class="final-totals">
            <thead>
              <tr class="is-size-7">
                <th>Tax Type</th>
                <th>Tax Rate</th>
                <th>Taxable Amount</th>
                <th>Tax Amount</th>
                <th>Tax Exemption Reason</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="is-size-7">{{ invoice.tax_type }}</td>
                <td class="is-size-7">{{ (invoice.total_tax_amount * 100 / invoice.total_excluding_tax).toFixed(2) }} %</td>
                <td class="is-size-7">{{ invoice.total_excluding_tax }}</td>
                <td class="is-size-7">{{ formatCurrency(invoice.total_tax_amount) }}</td>
                <td class="is-size-7">NOT APPLICABLE</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="$emit('close')"></button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'InvoicePreview',
    props: {
      showPreview: Boolean,
      invoice: {
        type: Object,
        default: () => ({}) // Ensure invoice is always an object
      },
      customer: {
        type: Object,
        default: () => ({}) // Ensure customer is always an object
      },
      sender: {
        type: Object,
        default: () => ({}) // Ensure sender is always an object
      },
      lhdn_validation: Object,
      qr_code_lhdn_url: String,
      qr_code_uuid_url: String,
      logo: String
    },
    data() {
      return {
        invoiceTypes: [
          { "Code": "01", "Description": "Invoice" },
          { "Code": "02", "Description": "Credit Note" },
          { "Code": "03", "Description": "Debit Note" },
          { "Code": "04", "Description": "Refund Note" },
          { "Code": "11", "Description": "Self-billed Invoice" },
          { "Code": "12", "Description": "Self-billed Credit Note" },
          { "Code": "13", "Description": "Self-billed Debit Note" },
          { "Code": "14", "Description": "Self-billed Refund Note" }
        ]
      };
    },
    computed: {
      aggregatedTaxTotals() {
        // Create a map to store totals by both tax_type and tax_rate
        const taxSummary = {};

        for (let item of this.invoice.items) {
          const { tax_type, tax_rate, total_excluding_tax, tax_amount } = item;

          // Create a unique key combining tax_type and tax_rate
          const key = `${tax_type}_${tax_rate}`;

          // If the key does not exist in the summary, initialize it
          if (!taxSummary[key]) {
            taxSummary[key] = {
              tax_type: tax_type,
              tax_rate: tax_rate,
              taxable_amount: 0,
              tax_amount: 0,
            };
          }

          // Accumulate the taxable amount and tax amount
          taxSummary[key].taxable_amount += parseFloat(total_excluding_tax);
          taxSummary[key].tax_amount += parseFloat(tax_amount);
        }

        // Convert the summary map into an array for easy iteration in the template
        return Object.values(taxSummary);
      }
    },
    methods: {
      formatCurrency(value) {
        if (!value) return 'MYR 0.00';
        return new Intl.NumberFormat('en-MY', { style: 'currency', currency: 'MYR' }).format(value);
      },
      getMappedInvoiceType(code) {
        const type = this.invoiceTypes.find(type => type.Code === code);
        return type ? type.Description : code;
      },
      getEntityLogo() {
        return this.logo || require('@/assets/default-entity-logo.png');
      }
    }
  }
  </script>
  
  <style scoped>
  .modal-content {
    width: 900px !important;
    margin: auto;
    position: relative;
  }

  .watermark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    font-size: 10em;
    color: rgba(0, 0, 0, 0.1); /* Light grey color with transparency */
    white-space: nowrap;
    z-index: 1; /* Ensure the watermark is above other elements */
    pointer-events: none; /* Allows interaction with underlying elements */
  }

  .box {
    position: relative;
    z-index: 2;
    background-color: white;
  }
  
  .modal {
    z-index: 1050; /* Ensure the modal is on the highest z-axis */
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 2em;
    text-align: right;
  }
  .div-title {
    font-weight: bold;
    margin-bottom: 5px;
  }
  .buyer-info,
  .identifiers {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px 0;
  }
  .buyer-info .left,
  .identifiers .left {
    width: 45%;
    padding-left: 10px;
  }
  .buyer-info .right,
  .identifiers .right {
    width: 35%;
    text-align: left;
    padding-right: 10px;
  }
  .line-items {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  .line-items th,
  .line-items td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  .line-items th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  .totals {
    text-align: right;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-right: 10px;
  }
  .final-totals {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  .final-totals th,
  .final-totals td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  .final-totals th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  .separator {
    border-top: 2px solid #ddd;
    margin: 20px 0;
  }
  
  .sender-logo {
    width: 100px !important; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
  }
  
  .summary {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .summary .left {
    width: 20%;
  }
  .summary .right {
    width: 80%;
  }
  .qr-code {
    width: 100px;
    height: 100px;
  }
  .is-small {
    font-size: 0.8em;
  }
  .has-text-danger {
    color: red;
  }
  </style>
  