<template>
  <div class="approve-invoice-page mt-5">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li><router-link to="/dashboard">Dashboard</router-link></li>
        <li class="is-active"><router-link to="/dashboard/invoices/approvals" aria-current="true">Approvals</router-link></li>
      </ul>
    </nav>
    <h2>Pending Invoices</h2>

    <!-- Loading overlay -->
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-content">
        <p>Loading...</p>
      </div>
    </div>

    <div class="column is-12">
      <!-- Conditionally render the Bulk Reject button only if rows are selected -->
      <div class="buttons mb-2" v-if="selectedInvoices.length">
        <button class="button is-danger" @click="bulkReject">Reject Selected</button>
      </div>

      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th class="is-size-7">
              <!-- Select All Checkbox -->
              <input type="checkbox" @change="selectAllInvoices" v-model="selectAll" />
            </th>
            <th class="is-size-7">Sender Name</th>
            <th class="is-size-7">Receiver Name</th>
            <th class="is-size-7">Doc Type</th>
            <th class="is-size-7">Doc Number</th>
            <th class="is-size-7">Issue Date</th>
            <th class="is-size-7">Document Total</th>
            <th class="is-size-7">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="invoice in pendingInvoices" :key="invoice.id">
            <td class="is-vcentered is-size-7">
              <!-- Row Checkbox -->
              <input type="checkbox" :value="invoice.uuid" v-model="selectedInvoices" />
            </td>
            <td class="is-vcentered is-size-7 has-text-left">{{ invoice.supplier_name }}</td>
            <td class="is-vcentered is-size-7 has-text-left">{{ invoice.customer.name }}</td>
            <td class="is-vcentered is-size-7 has-text-left">{{ invoice.invoice_type }}</td>
            <td class="is-vcentered is-size-7">{{ invoice.invoice_number }}</td>
            <td class="is-vcentered is-size-7">{{ invoice.invoice_date }}</td>
            <td class="is-vcentered is-size-7 has-text-right">{{ invoice.invoice_currency + " " + formatCurrency(invoice.total_payable_amount) }}</td>
            <td>
              <div>
                <span class="icon-container" @click="previewInvoice(invoice)" title="Preview Invoice">
                  <font-awesome-icon icon="fa-eye" class="has-text-success"/>
                </span>
                <span v-if="invoice.created_by !== currentUserId" class="icon-container ml-4" @click="confirmChangeStatus(invoice.uuid, 'Approved')" title="Approve Invoice">
                  <font-awesome-icon icon="check" class="has-text-warning"/>
                </span>
                <span v-if="invoice.created_by !== currentUserId" class="icon-container ml-4" @click="openRejectionModal(invoice.uuid)" title="Reject Invoice">
                  <font-awesome-icon icon="times" class="has-text-danger"/>
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 1" class="button is-ghost is-small">Previous</button>
        <span class="strong is-size-7"> {{ currentPage * itemsPerPage }} of {{ totalDocuments }} Documents</span>
        <button @click="nextPage" :disabled="currentPage === totalPages" class="button is-ghost is-small">Next</button>
      </div>
    </div>

    <!-- Invoice Preview Modal -->
    <InvoicePreview :showPreview="showPreview" :logo="previewLogo" :invoice="selectedInvoice" :sender="selectedSender" :customer="selectedCustomer" @close="showPreview = false" v-if="selectedInvoice && selectedCustomer" />

    <!-- Rejection Modal -->
    <div class="modal" :class="{ 'is-active': showRejectionModal }">
      <div class="modal-background" @click="closeRejectionModal"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Rejection Reason</p>
          <button class="delete" aria-label="close" @click="closeRejectionModal"></button>
        </header>
        <section class="modal-card-body">
          <textarea class="textarea" v-model="rejectionReason" placeholder="Enter the reason for rejection"></textarea>
        </section>
        <footer class="buttons modal-card-foot">
          <button class="button is-danger" @click="rejectInvoice">Reject</button>
          <button class="button" @click="closeRejectionModal">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { toast } from 'bulma-toast';
import InvoicePreview from '@/components/CreateInvoice/InvoicePreview.vue';

export default {
  name: 'InvoiceApproval',
  components: {
    InvoicePreview
  },
  data() {
    return {
      pendingInvoices: [],
      showPreview: false,
      selectedInvoice: null,
      selectedCustomer: null,
      currentUserId: null,
      showRejectionModal: false,
      rejectionReason: '',  // Should be an empty string, not null
      invoiceToReject: null, // Can stay null, but ensure it's correctly handled
      itemsPerPage: 10,
      currentPage: 1,
      totalPages: 1,
      totalDocuments: '',
      selectedInvoices: [],  // Initialize as an empty array
      selectAll: false,      // Initialize as false
      isLoading: false       // Initialize as false
    };
  },
  created() {
    this.fetchCurrentUserId();
    this.fetchPendingInvoices();
  },
  methods: {
    fetchCurrentUserId() {
      const userId = localStorage.getItem('userid');
      this.currentUserId = parseInt(userId, 10);
    },
    fetchPendingInvoices() {
      const params = new URLSearchParams();
      params.append('page', this.currentPage);
      params.append('page_size', this.itemsPerPage);
      axios.get('/api/v1/invoices/pending/', { params })
        .then(response => {
          this.pendingInvoices = response.data.results;
          this.totalDocuments = response.data.count;
          this.totalPages = Math.ceil(response.data.count / this.itemsPerPage); 
        })
        .catch(error => {
          console.error('There was an error fetching the pending invoices!', error);
          toast({
            message: 'Error fetching pending invoices!',
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 5000,
            position: 'top-right'
          });
        });
    },
    // Select all invoices
    selectAllInvoices() {
      this.selectedInvoices = this.selectAll
        ? this.pendingInvoices.map(invoice => invoice.uuid) // Select all
        : []; // Deselect all
    },
    // Open rejection modal
    openRejectionModal(uuid) {
      this.invoiceToReject = uuid;
      this.showRejectionModal = true;
    },
    // Close rejection modal
    closeRejectionModal() {
      this.showRejectionModal = false;
      this.rejectionReason = '';
      this.invoiceToReject = null;
    },
    // Reject invoice and update status
    rejectInvoice() {
      this.confirmChangeStatus(this.invoiceToReject, 'Rejected', this.rejectionReason);
      this.closeRejectionModal();
    },
    confirmChangeStatus(invoiceUuid, status, rejectionReason = null) {
      const confirmationMessage = `Are you sure you want to mark this invoice ${status.toLowerCase()}? This action is not reversible.`;
      if (window.confirm(confirmationMessage)) {
        this.changeInvoiceStatus(invoiceUuid, status, rejectionReason);
      }
    },
    async changeInvoiceStatus(invoiceUuid, status, rejectionReason = null) {
      try {
        await axios.post(`/api/approve-invoice/${invoiceUuid}/`, { status: status, rejection_reason: rejectionReason }, {
          withCredentials: true
        });
        this.fetchPendingInvoices();
        toast({
          message: `Invoice ${status.toLowerCase()} successfully!`,
          type: 'is-success',
          dismissible: true,
          pauseOnHover: true,
          duration: 5000,
          position: 'top-right'
        });
      } catch (error) {
        console.error(`There was an error ${status.toLowerCase()}ing the invoice!`, error);
        toast({
          message: `Error ${status.toLowerCase()}ing invoice!`,
          type: 'is-danger',
          dismissible: true,
          pauseOnHover: true,
          duration: 5000,
          position: 'top-right'
        });
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchPendingInvoices();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchPendingInvoices();
      }
    },
    previewInvoice(invoice) {
      this.selectedInvoice = invoice;
      this.selectedCustomer = invoice.customer;
      this.selectedSender = invoice.sender;
      
      // Check if selfbilling is true or false
      if (invoice.selfbilling) {
        // Get the sender_entities from localStorage and parse it
        const senderEntities = JSON.parse(localStorage.getItem('sender_entities')) || [];

        // Find the entity that matches the tenant_account
        const matchingEntity = senderEntities.find(entity => entity.entity_code === invoice.tenant_account);

        // If a matching entity is found, set the logo from that entity
        this.previewLogo = matchingEntity?.logo || null;
      } else {
        // Use the sender logo from the invoice if selfbilling is false
        this.previewLogo = invoice.sender.logo;
      }

      // Show the invoice preview modal
      this.showPreview = true;
    },
    formatCurrency(value) {
      if (!value) return '';
      return parseFloat(value).toLocaleString('en-US', {
        style: 'decimal',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      });
    },
  }
};
</script>

<style scoped>
.table th, .table td {
  text-align: center;
}
.icon-container {
  cursor: pointer;
}

/* Loading overlay styles */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-content {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}
</style>
