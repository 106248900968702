<template>
  <div class="container mt-5">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li><router-link to="/dashboard">Dashboard</router-link></li>
        <li class="is-active"><router-link to="/dashboard/report" aria-current="true">Reports</router-link></li>
      </ul>
    </nav>
    <form @submit.prevent="getReport" class="box">
      <div class="columns is-multiline is-mobile">
        <div class="column is-one-third">
          <div class="field">
            <label class="label is-small">Start Date:</label>
            <div class="control">
              <input class="input is-small" type="date" v-model="form.start_date" />
            </div>
          </div>
        </div>
        <div class="column is-one-third">
          <div class="field">
            <label class="label is-small">End Date:</label>
            <div class="control">
              <input class="input is-small" type="date" v-model="form.end_date" />
            </div>
          </div>
        </div>
        <div class="column is-one-third">
          <div class="field">
            <label class="label is-small">Sender Entity Code:</label>
            <div class="control">
              <div class="select is-small">
                <select v-model="form.sender_entity_code">
                  <option value="">Select Sender Entity Code</option>
                  <option v-for="entity in userProfile.sender_entities" :key="entity.id" :value="entity.entity_code">{{ entity.entity_code }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-one-third">
          <div class="field">
            <label class="label is-small">Sender Store Code:</label>
            <div class="control">
              <div class="select is-small">
                <select v-model="form.sender_store_code">
                  <option value="">Select Sender Store Code</option>
                  <option v-for="store in userProfile.store_code" :key="store.id" :value="store.code">{{ store.code }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-one-third">
          <div class="field">
            <label class="label is-small">Invoice Type:</label>
            <div class="control">
              <div class="select is-small">
                <select v-model="form.invoice_type">
                  <option value="">Select Invoice Type</option>
                  <option v-for="type in invoiceTypes" :key="type.Code" :value="type.Code">{{ type.Description }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-one-third">
          <div class="field">
            <label class="label is-small">Status:</label>
            <div class="control">
              <div class="select is-small">
                <select v-model="form.status">
                  <option value="">Select Status</option>
                  <option v-for="status in statusOptions" :key="status" :value="status">{{ status }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-one-third">
          <div class="field">
            <label class="label is-small">Buyer:</label>
            <div class="control">
              <multiselect
                v-model="form.buyer"
                :options="buyers"
                label="name"
                track-by="id"
                placeholder="Select Buyer"
                :searchable="true"
                :close-on-select="true"
                :show-labels="true"
                class="input is-small"
              ></multiselect>
            </div>
          </div>
        </div>
        <div class="column is-full">
          <div class="field">
            <div class="control">
              <button class="button is-primary is-small" type="submit" :disabled="!isFilterApplied || isGeneratingReport">Get Report</button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <hr>

    <div>
      <h2 class="title is-4">Past Reports:</h2>
      <table class="table is-fullwidth is-striped is-small">
        <thead>
          <tr class="is-small">
            <th>Report Name</th>
            <th>Requester</th>
            <th>Status</th>
            <th>Download</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr class="is-small" v-for="report in sortedReports" :key="report.id">
            <td>{{ formatDate(report.created_at) }}_{{ formatTime(report.created_at) }}.csv</td>
            <td>{{ userProfile.username }}</td>
            <td>
              <span v-if="report.status === 'Generating'"><em>Generating...</em></span>
              <span v-else-if="report.status === 'Failed'"><em>Failed</em></span>
              <span v-else>Completed</span>
            </td>
            <td v-if="report.status === 'Completed'">
              <a :href="report.file_path" target="_blank">CSV</a>
            </td>
            <td v-else>-</td>
            <td>
              <div>
                <button v-if="report.status === 'Generating'" class="button is-small is-info" @click="fetchUserProfile" title="Refresh">
                  Refresh
                </button>
                <button v-if="report.status !== 'Generating'" class="button is-small is-danger ml-2" @click="confirmDelete(report.id)" title="Delete">
                  Delete
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';

export default {
  components: { Multiselect },
  data() {
    return {
      form: {
        start_date: '',
        end_date: '',
        submission_date: '',
        sender_entity_code: '',
        sender_store_code: '',
        document_type: '',
        invoice_type: '',
        created_by: '',
        status: '',
        buyer: null,
      },
      reportUrl: '',
      userProfile: {},
      isGeneratingReport: false,
      buyers: [],
      statusOptions: [
        'Parsing Error', 'Pending Approval', 'Submitted', 'Submission Failed',
        'Validated', 'Approved', 'Draft', 'Invalid', 'Approval Rejected', 'User Cancelled'
      ],
      invoiceTypes: [
        { "Code": "INVOICE", "Description": "Invoice" },
        { "Code": "CREDIT NOTE", "Description": "Credit Note" },
        { "Code": "DEBIT NOTE", "Description": "Debit Note" },
        { "Code": "REFUND NOTE", "Description": "Refund Note" },
        { "Code": "SELF BILLED INVOICE", "Description": "Self-billed Invoice" },
        { "Code": "SELF BILLED CREDIT NOTE", "Description": "Self-billed Credit Note" },
        { "Code": "SELF BILLED DEBIT NOTE", "Description": "Self-billed Debit Note" },
        { "Code": "SELF BILLED REFUND NOTE", "Description": "Self-billed Refund Note" }
      ],
    };
  },
  created() {
    this.fetchUserProfile();
    this.fetchBuyers();
  },
  computed: {
    isFilterApplied() {
      return (
        this.form.start_date || this.form.end_date || this.form.sender_entity_code || this.form.sender_store_code || 
        this.form.invoice_type || this.form.status || this.form.buyer
      );
    },
    sortedReports() {
      if (this.userProfile.reports) {
        return this.userProfile.reports.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      }
      return [];
    },
  },
  methods: {
    async getReport() {
      try {
        this.isGeneratingReport = true;
        const response = await axios.get('/api/v1/report/', { params: this.form });
        this.reportUrl = response.data.report_url;
        this.fetchUserProfile();  // Refresh the user profile to get the updated reports list
      } catch (error) {
        console.error('Error fetching report:', error);
      } finally {
        this.isGeneratingReport = false;
      }
    },
    async fetchUserProfile() {
      try {
        const response = await axios.get('/api/v1/user-profile/');
        this.userProfile = response.data;
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    },
    async fetchBuyers() {
      try {
        const response = await axios.get('/api/v1/customers/');
        this.buyers = response.data;
      } catch (error) {
        console.error('Error fetching buyers:', error);
      }
    },
    async deleteReport(reportId) {
      try {
        await axios.delete(`/api/v1/report/${reportId}/`);
        this.fetchUserProfile();  // Refresh the user profile to get the updated reports list
      } catch (error) {
        console.error('Error deleting report:', error);
      }
    },
    confirmDelete(reportId) {
      if (window.confirm("Are you sure you want to delete this report?")) {
        this.deleteReport(reportId);
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toISOString().split('T')[0].split('-').reverse().join('_');
    },
    formatTime(dateString) {
      const date = new Date(dateString);
      return date.toTimeString().split(' ')[0].replace(/:/g, '');
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 20px;
}

.box {
  margin-bottom: 20px;
}

.notification {
  margin-top: 20px;
}

.title {
  margin-bottom: 10px;
}

.table {
  margin-top: 20px;
  font-size: 0.75rem; /* Smallest font size */
}

.button {
  cursor: pointer;
}

.is-small {
  font-size: 0.75rem; /* Smallest font size */
}
</style>
